<template>
	<div id="view">
		<div class="html" v-html="itemListData[current].content"></div>
		<div class="gao"></div>
		<div class="bnt_vi">
			<div class="bnt1" @click="shang()">{{current == 0?"返回":"上一段"}}</div>
			<div class="bnt2" @click="xia()">{{current >= itemListData.length-1 ? "返回":"下一段"}}</div>
		</div>
	</div>
</template>

<script>
	import {
		Notify,
		Cell,
		CellGroup,
		Field,
		Icon,
		Loading,
	} from 'vant';

	import https from '../https.js'
	export default {
		components: {
			[Notify.name]: Notify,
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup,
			[Field.name]: Field,
			[Icon.name]: Icon,
			[Loading.name]: Loading,
		},
		data() {

			return {
				html: "",
				Loadings: false,
				te404: false,
				cideData: {
					title: "",
					content: ""
				},
				itemListData: [],
				currentData: [],
				current: 0
			}
		},
		created() {
			// localStorage   长期
			// sessionStorage; 短期
			// let id = this.$route.params.id
			let id = this.$route.query.id
			let current = this.$route.query.current
			console.log(id, current)
			this.id = id;
			this.current = current ? current : 0
			var storage = window.sessionStorage;
			var itemListData = storage.getItem('itemListData_' + id)
			this.itemListData = JSON.parse(itemListData);
			console.log(this.itemListData)
			this.onCurrent()

		},
		methods: {
			shang() {
				var current = parseFloat(this.current)
				if (current <= 0) {
					// console.log(current)
					this.$router.go(-1);
				} else {
					current -= 1;
					this.current = current
				}
				this.onCurrent()
			},
			xia() {
				var current = parseFloat(this.current)
				var itemListData = this.itemListData
				console.log(current)
				// console.log(current,itemListData.length-1)
				if (current >= itemListData.length - 1) {
					// console.log(current)
					this.$router.go(-1);
				} else {
					current += 1;
					this.current = current
				}
				this.onCurrent()
			},
			onCurrent() {
				
				
				var current = this.current;
				var storage = window.localStorage;
				var a = storage.getItem('currentSybData_'+this.id);
				console.log("currentSybData_",typeof a,a)
				
				if(a){
					var currentData = this.currentData = JSON.parse(a) ||[];
					currentData[current] = true;
					storage.setItem('currentSybData_'+this.id, JSON.stringify(currentData));
					
				}else{
					var currentData = this.currentData;
					currentData[current] = true;
					storage.setItem('currentSybData_'+this.id, JSON.stringify(currentData));
				}
				
				
				 
				 
			}
		}
	}
</script>

<style scoped="scoped">
	.html {
		padding: 4px 30px;
		text-align: justify;
		word-wrap: break-word;
	}

	.h1 {
		padding: 32px 30px;
		font-size: 38px;
		font-weight: bold;
	}

	.bnt_vi {
		margin: 0 90px;
		display: flex;
		justify-content: space-between;
		position: fixed;
		bottom: 50px;
	}

	.bnt_vi>div {
		width: 268px;
		height: 88px;
		font-weight: bold;
		border-radius: 20px;
		font-size: 30px;
		color: #fff;
		text-align: center;
		line-height: 88px;
	}

	.bnt1 {
		/* background: #4EA5FF;
		box-shadow: 0px 16px 20px 0px rgba(78, 165, 255, 0.18); */
		background: #7EB49C;
		box-shadow: 0px 16px 20px 0px rgba(126, 180, 156, 0.42);
	}
	.bnt2 {
		margin-left: 34px;
		/* background: #FF4B5F;
		box-shadow: 0px 16px 20px 0px rgba(255, 75, 95, 0.18); */
		background: #FF9400;
		box-shadow: 0px 16px 20px 0px rgba(255, 148, 0, 0.41);
	}
</style>
